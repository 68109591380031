.hero {
  padding-top: 10vh;
  align-items: flex-start;
  margin: 0 2vw;
}

.hero h3 {
  color: var(--secondary-color);
  font-size: 2.25rem;
  margin: 0;
}

.hero h2 {
  color: var(--tertiary-color);
}

.hero h4 {
  color: var(--secondary-color);
}

@media (max-width: 480px) {
  .hero {
    padding-top: 0;
    align-items: start;
    margin: 0;
  }
}

@media (min-width: 1300px) {
  .hero {
    padding: 0;
  }
}
