.offcanvas {
  transition: transform 1s ease-in-out;
  --bs-offcanvas-width: 100%;
}

.offcanvas-body {
  padding: 6vh;
  display: flex;
  justify-content: flex-end;
  align-content: flex-start;
  flex-wrap: wrap;
}

.offcanvas-body .contact-button {
  display: none;
}

.offcanvas-button {
  display: flex;
  /* align-items: flex-end; */
  justify-content: flex-end;
}

.offcanvas-toggle {
  /* Add styles for the custom hamburger icon */
  background-image: url("../images/hamburger.svg"); /* Replace with your custom hamburger icon image */
  background-size: 1.5rem; /* Set the size of your icon */
  background-repeat: no-repeat;
  background-position: center;
  border: none; /* Remove the default border */
  padding: 0; /* Remove padding */
  width: 2.5rem; /* Set the width of the toggle button */
  height: 2.5rem; /* Set the height of the toggle button */
}

.btn-close {
  background-image: url("../images/exit.svg"); /* Replace with your custom hamburger icon image */
  background-size: 1.5rem; /* Set the size of your icon */
  background-repeat: no-repeat;
  background-position: center;
  border: none; /* Remove the default border */
  padding: 0; /* Remove padding */
  width: 2.5rem; /* Set the width of the toggle button */
  height: 2.5rem; /* Set the height of the toggle button */
  opacity: 1;
}

.offcanvas-header .btn-close {
  margin: 0;
  padding: 0;
}

.no-scroll {
  overflow: hidden;
}

@media (max-width: 990px) {
  .offcanvas-body {
    font-family: var(--heading-font-family);
    font-size: 1.25rem;
    font-weight: bold;
    margin: 0;
    text-align: right;
  }

  .offcanvas-body .contact-button {
    display: block;
  }

  .offcanvas-header {
    padding: 3.5vh 6vw;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    overflow: hidden;
  }
}
