/* to load animation for a specific spinner */
@import "~spinners-react/lib/SpinnerRoundFilled.css";

:root {
  --primary-color: #001858;
  --secondary-color: #4c5b83;
  --tertiary-color: #eee4cc;
  --contrast-color: #ffffff;
  --background-color: #fafafa;

  --dark-primary-color: #848fb9;
  --dark-secondary-color: #4c5b83;
  --dark-tertiary-color: #f0f4f8;
  --dark-contrast-color: #f0f4f8;
  --dark-background-color: #081623;

  --heading-font-family: "Hanken Grotesk", sans-serif;
  --subheading-font-family: "Anaheim", sans-serif;
}

html {
  scroll-behavior: smooth;
}

/* body {
  background: var(--background-color);
} */

section {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-end;
  min-height: 100vh;
  margin: 0 10vw 8vh;
}

.container-fluid {
  padding: 0vw;
}

h1,
h3,
h5,
a {
  font-family: var(--heading-font-family);
  color: var(--primary-color);
}

h2,
h4 {
  font-family: var(--subheading-font-family);
  margin: 0;
}

h1 {
  font-size: 4.5rem;
  font-weight: bolder;
  letter-spacing: 0.25rem;
  line-height: 1.25em;
}

h2 {
  font-size: 3.25rem;
}

h3 {
  font-size: 3rem;
  font-weight: bolder;
  margin: 2rem 0;
}

h4 {
  color: var(--tertiary-color);
  font-size: 1.5rem;
  font-weight: lighter;
  letter-spacing: 0.125rem;
  line-height: 1.3;
}

h5 {
  margin: 0;
}

p {
  font-size: 0.8rem;
  font-family: var(--secondary-font-family);
  color: var(--secondary-color);
}

ul {
  padding-left: 1rem;
}

li {
  font-size: 0.75rem;
  font-family: var(--secondary-font-family);
  color: var(--secondary-color);
}

a {
  display: inline-block;
  text-decoration: none;
  letter-spacing: 0.1em;
}

a:hover {
  color: var(--tertiary-color);
  cursor: pointer;
  transition: cubic-bezier(0.645, 0.045, 0.355, 1);
  transform: scale(1.125);
  opacity: 100%;
}

.btn {
  font-size: 0.75rem;
  font-family: var(--heading-font-family);
  letter-spacing: 0.1rem;
  color: var(--background-color);
  background-color: var(--secondary-color);
  border: none;
  border-radius: 50px;
  padding: 1.25em 2.25em;
  margin-top: 3em;
}

.btn:hover {
  background-color: var(--background-color);
  color: var(--primary-color);
  border: 1px solid var(--secondary-color);
}

.btn:first-child:active {
  background-color: var(--primary-color);
}

.btn-close:focus {
  outline: none;
  box-shadow: none;
  opacity: 0.5;
}

.vertical-line {
  display: flex;
  height: 25vh;
  justify-content: center;
  margin-top: 2.5vh;
}

.vr {
  color: var(--primary-color);
  opacity: 1;
  width: 0.5px;
}

/* Mobile */
@media (max-width: 480px) {
  section {
    padding-top: 10vh;
    align-items: end;
    margin: 0;
  }
}

/* Mobile */
@media (max-width: 900px) {
  section {
    padding-top: 10vh;
    align-items: end;
  }

  h1 {
    font-size: 3.25rem;
  }

  h2 {
    font-size: 2.25rem;
  }

  h3 {
    font-size: 2.5rem;
  }

  h4 {
    font-size: 1.5rem;
    margin-right: 4vw;
  }

  p {
    font-size: 0.7rem;
  }
}

/* Large screens */
@media (min-width: 1300px) {
  h1 {
    font-size: 8rem;
  }

  h2 {
    font-size: 6rem;
  }

  h3 {
    font-size: 3.25rem;
  }

  h4 {
    font-size: 2rem;
  }
}
