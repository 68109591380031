.accordion {
  width: 100%;
  --bs-accordion-border-radius: 0px;
  --bs-accordion-btn-focus-box-shadow: none;
  z-index: 0;
}

.accordion-body {
  padding: 0 2.5rem 0;
}

.accordion-item {
  border: none;
  margin-bottom: 1.5rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.accordion-button {
  color: var(--secondary-color);
  z-index: revert;
}

.accordion-button:not(.collapsed) {
  color: var(--contrast-color);
  background-color: var(--secondary-color);
  box-shadow: none;
  line-height: 1.5em;
}

.accordion-button::after {
  filter: invert(47%) sepia(2%) saturate(3158%) hue-rotate(352deg)
    brightness(150%) contrast(90%);
}

.accordion-button:not(.collapsed)::after {
  filter: invert(500%) sepia(1%) saturate(1%) hue-rotate(220deg)
    brightness(500%) contrast(104%);
}

.accordion-item::before {
  border-bottom-right-radius: 50px;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

@media (max-width: 539px) {
  .accordion-body {
    padding: 0 2rem 0;
  }
}
