.about p {
  text-align: justify;
}

.about-content hr {
  width: 6vw;
  left: -2vw;
  position: relative;
  border-top: 1.5px solid var(--primary-color);
  opacity: 1;
}

.about img {
  height: 16rem;
  vertical-align: baseline;
  padding-top: 2rem;
}

.about-content {
  display: flex;
  padding: 1.5rem 2.5rem 2.5rem;
  align-items: center;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.about-text {
  margin-left: 1.5rem;
}

@media (max-width: 540px) {
  .about-content {
    display: block;
    padding: 0 2rem 2rem;
  }

  .about-text {
    margin-left: 0;
  }
  .about img {
    object-fit: fill;
    padding-top: 1.5rem;
  }

  .about hr {
    width: 10vw;
    left: -4vw;
  }
}
