.certifications p {
  text-align: justify;
}

.certifications hr {
  width: 6vw;
  left: -2vw;
  position: relative;
  border-top: 1.5px solid var(--primary-color);
  opacity: 1;
}

.certifications-content {
  display: flex;
  margin-bottom: 4vh;
}

.card {
  display: grid;
  padding-top: 1.5rem;
  border: none;

  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.card-img {
  width: 45%;
  margin: 0 auto;
}

.card h4 {
  margin: 1.5rem 0 0;
  text-align: left;
}

.card-body {
  padding: 0 2.5rem 2.5rem;
}

.card-group > .card + .card {
  margin-left: 2.5rem;
}

.certifications .btn {
  align-self: center;
  margin: 6vh 0 12vh;
}

@media (max-width: 540px) {
  .certifications {
    flex-direction: column;
  }

  .card-body {
    padding: 0 2rem 2rem;
  }

  .card-group > .card + .card {
    margin: 2rem 0;
  }

  .certifications hr {
    width: 10vw;
    left: -4vw;
  }
}
