.content {
  padding: 0vh 12vw;
}

.main {
  margin: 0 auto;
  max-width: 1600px;
}

@media (max-width: 900px) {
  .content {
    padding: 0 10vw;
  }
}
