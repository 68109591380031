footer {
  text-align: center;
  z-index: 1;
  position: relative;
}

footer h3 {
  font-weight: bolder;
  margin: 0.5rem 0rem;
  color: var(--background-color);
}

footer p {
  color: var(--background-color);
  margin: 0;
}

footer hr {
  display: none;
}

.footer-main {
  background-color: var(--primary-color);
  padding: 10vh 15vw 0vh;
}

.footer-main a {
  color: var(--contrast-color);
  font-size: 0.8rem;
  letter-spacing: normal;
}

.footer-icons {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 4vh 15vw 10vh;
}

.footer-icons a {
  font-size: 1.25rem;
}

.footer-end {
  display: flex;
  justify-content: space-between;
  background-color: var(--secondary-color);
  padding: 10vh 7vw;
}

.footer-end .nav-link {
  color: var(--contrast-color);
  font-family: var(--secondary-font-family);
  font-size: 0.8rem;
  letter-spacing: normal;
  margin: 0 0 0 1.5rem;
}

.footer-end p {
  margin: 0;
  color: var(--contrast-color);
}

@media (max-width: 768px) {
  footer hr {
    display: block;
    color: var(--background-color);
    margin: 1rem 4rem;
  }

  .footer-main {
    padding: 5vh 10vw 0vh;
  }

  .footer-main h3 {
    font-size: 2.5rem;
  }

  .footer-end {
    flex-direction: column-reverse;
    padding: 5vh 7vw;
  }

  .footer-end .nav {
    display: inline;
    margin-bottom: 1.5vh;
  }

  .footer-end .nav-link {
    margin: 0vh 0vw 1.5vh;
  }

  .footer-end p {
    margin-top: 3vh;
  }

  .footer-icons {
    padding: 5vh 0vw;
  }
}
