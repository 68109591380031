.projects p {
  text-align: justify;
}

.projects-content hr {
  width: 6vw;
  left: -2vw;
  position: relative;
  border-top: 1.5px solid var(--primary-color);
  opacity: 1;
}

.projects img {
  height: 18rem;
  margin-right: 4vw;
}

.projects-content {
  background-color: var(--contrast-color);
  padding: 1.5rem 2.5rem 2.5rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

@media (max-width: 768px) {
  .projects-content hr {
    width: 10vw;
    left: -4vw;
  }
}

@media (max-width: 539px) {
  .projects-content {
    flex-direction: column;
    padding: 2rem 2rem;
  }

  .projects img {
    height: 10rem;
    align-self: start;
    margin-bottom: 4vh;
  }
}
