.experience {
  padding-top: 20vh;
}

.experience h2 {
  font-size: larger;
}

.experience h4 {
  font-size: medium;
  margin-bottom: 0.75em;
}

.experience hr {
  margin: 1.5rem 0rem;
  width: 6vw;
  left: -2vw;
  position: relative;
  border-top: 1.5px solid var(--primary-color);
  opacity: 1;
}

.responsibilities-list li {
  list-style-type: circle;
  margin-left: 1rem;
}

.tech-blocks li {
  font-size: x-small;
  display: inline-block;
  border: 1px solid var(--tertiary-color);
  padding: 0.55rem;
  margin: 0 0.5rem 0.8rem 0;
  border-radius: 15px;
}

@media (max-width: 768px) {
  .experience {
    padding-top: 15vh;
  }
  .experience hr {
    width: 10vw;
    left: -4vw;
  }

  .responsibilities-list li {
    margin-left: 0;
  }
}
