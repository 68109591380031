.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  padding: 4vh 3.5vw;
  width: 100%;
  box-shadow: rgba(149, 157, 165, 0) 0px 8px 24px;
  background-color: var(--contrast-color);
  z-index: 1;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.header.show-box-shadow {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.header.at-top {
  box-shadow: rgba(149, 157, 165, 0) 0px 8px 24px; /* Remove the box shadow at the top */
}

.header.hidden {
  transform: translateY(-100%);
}

.navbar-collapse {
  flex-grow: 0;
}

.navbar-expand-lg {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 0;
}

.navbar-toggler {
  color: var(--background-color);
}

.nav-link {
  color: var(--primary-color);
  padding: 0;
  font-size: 0.8rem;
}

.nav-link:hover {
  color: var(--tertiary-color);
  text-decoration: underline;
  text-underline-offset: 5px;
}

.navbar-nav .nav-link.active,
.navbar-nav .show > .nav-link {
  color: var(--primary-color);
}

.navbar-nav .nav-link.active:hover,
.navbar-nav .show > .nav-link {
  color: var(--tertiary-color);
}

.navbar-brand {
  color: var(--primary-color);
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar-brand img {
  width: 2em;
}

.navbar-toggler-icon {
  display: none;
}

.navbar-toggler:focus {
  box-shadow: none;
  opacity: 0.5;
}

.show-header {
  transform: translateY(0);
  transition: transform 0.3s ease;
}

.hide-header {
  transform: translateY(-100%);
  transition: transform 0.3s ease;
}

@media (min-width: 1300px) {
  .nav-link {
    font-size: 1.2rem;
  }
}

@media (max-width: 990px) {
  .header {
    padding: 3.5vh 6vw;
  }

  .navbar-brand img {
    width: 2em;
  }

  .nav-link {
    margin-bottom: 1vh;
    font-size: 1rem;
  }
}
