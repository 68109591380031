.vertical-email-bar {
  position: fixed;
  bottom: 0px;
  left: auto;
  right: 5vw;
}

.email {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.email-contact {
  writing-mode: vertical-rl;
  padding-bottom: 0.75em;
  font-family: var(--subheading-font-family);
}

@media (max-width: 768px) {
  .vertical-email-bar {
    display: none;
  }
}
