.vertical-icon-bar {
  position: fixed;
  bottom: 0px;
  left: 5vw;
  right: auto;
}

.vertical-icon-bar ul {
  list-style: none;
  padding: 0;
  margin: 0;
  font-family: var(--default-font-family);
}

.icon-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon:hover {
  transform: scale(1.125);
  color: var(--tertiary-color);
}

.icon-list li {
  font-size: 1.25rem;
  color: var(--primary-color);
  letter-spacing: 0.2rem;
  line-height: 1.3;
  text-align: center;
  padding: 0.75rem 0rem;
  margin-bottom: 0.75rem;
}

@media (max-width: 768px) {
  .vertical-icon-bar {
    display: none;
  }
}
